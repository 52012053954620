html,
body {
    font-family: var(--theme-font);
}

strong,
b {
    font-family: var(--theme-bold-font);
}

div.linear-flex-block {
    display: flex;
    flex-direction: row;
    &.mobile {
        flex-direction: column;
    }
}

h3.header {
    font-size: 1.3em;
}

h4.header {
    font-size: 1.1em;
}

h5.header {
    font-size: 0.95em;
}

h3.header,
h4.header,
h5.header {
    margin: 0 0 5px;
    font-weight: bold;
    font-family: var(--theme-bold-font);
    &.primary {
        color: var(--ion-color-primary);
    }
}

p.text {
    font-size: 0.9em;
    margin: 0 0 3px;
}

.quiet-link {
    color: var(--ion-text-color);
    text-decoration: none;
    &:not(.no-hover):hover {
        text-decoration: underline;
        color: var(--ion-color-primary);
    }
}
