#Portal {
    font-family: var(--theme-font);
    border-top: 8px solid var(--ion-color-primary);
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    .contained {
        display: flex;
        flex-direction: column;
        padding: 20px;
        max-width: 1200px;
        margin: 0 auto;
        &.mobile {
            padding: 5px 0 20px;
            .page-header {
                padding: 0 0 19px;
            }
        }
    }
    .page-header {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        padding: 40px 0;
        padding-right: 5px;
        .store {
            align-items: center;
            gap: 10px;
            .storelogo {
                max-width: 200px;
            }
        }
        .person {
            text-align: right;
        }
        &.mobile {
            text-align: center;
            .store {
                width: 100%;
                margin-bottom: 20px;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                .contact {
                    margin-right: 15px;
                    padding: 15px 5px;
                    font-size: 1.6em;
                    .dealer-contact {
                        padding-left: 15px;
                    }
                }
            }
        }
    }
    .footer {
        justify-content: flex-end;
        margin-right: 10px;
        font-size: 0.5em;
        .provided {
            position: relative;
            right: 15px;
            top: 9px;
        }
        .lmilogo {
            width: 65px;
        }
        &.mobile {
            margin: 0 auto;
        }
    }
}
