$border-color: rgba(0, 0, 0, 0.3);
$done: #b1b1b1;
$incompleteStatus: #ffffff;

.tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .eta {
        margin-top: 5px;
        position: relative;
        top: 4px;
        background-color: var(--ion-color-step-250);
        padding: 2px 10px;
        border-radius: 5px;
        font-weight: 300;
        font-size: 0.9em;
        strong {
            font-size: 1em;
        }
    }
    .notifications {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        top: 7px;
    }
}

.mobile-status {
    padding: 5px 10px 5px 20px;
    font-size: 0.9em;
    position: relative;
    left: 20px;
    text-transform: uppercase;
    .flag {
        position: absolute;
        left: -10px;
        height: 19px;
        width: 19px;
        background-color: var(--ion-color-light-tint);
        transform: rotate(45deg);
    }
}

#OrderStatusBar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 8px;

    .current-status {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
        background-color: var(--ion-color-step-100);
        margin-top: 8px;
        border-radius: 8px;
        padding: 10px 21px 25px;

        .order-status-item {
            position: relative;
            display: flex;
            flex: 1;
            height: 85px;

            .status-block {
                display: grid;
                text-align: center;
                align-items: center;
                gap: 8px;
                grid-template-rows: repeat(auto-fit, minmax(20px, 1fr));
                width: 100%;

                .status {
                    text-transform: uppercase;
                    line-height: 1em;
                    height: 15px;
                    font-family: var(--theme-bold-font);

                    .sub {
                        font-size: 0.7em;
                        line-height: 11px;
                        height: 11px;
                    }

                    &.current-stage {
                        font-weight: bold;
                    }
                }

                .meter {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $incompleteStatus;
                    border-top: 1px solid $border-color;
                    border-bottom: 1px solid $border-color;
                    color: #bbbbbb;
                    position: relative;
                    height: 43px;

                    .status-icon {
                        flex-basis: 50%;
                        margin: 8px 0;
                        font-size: 1.8em;
                        height: 35px;
                        width: 35px;

                        svg {
                            height: 26px;
                            width: 34px;
                        }

                        .icon-path {
                            transform: scale(0.255);
                            fill: #bbbbbb;
                            text-align: center;
                        }
                    }

                    &.left-cap {
                        border-radius: 25px 0 0 25px;
                        border-left: 2px solid $border-color;
                    }

                    &.right-cap {
                        border-radius: 0 25px 25px 0;
                        border-right: 2px solid $border-color;
                    }

                    &.complete {
                        background: $done;
                        text-shadow: -1px 1px 2px black;

                        .icon-path {
                            fill: white;
                            -webkit-filter: drop-shadow(13px 13px 10px rgba(0, 0, 0, 0.7));
                            filter: drop-shadow(13px 13px 10px rgba(0, 0, 0, 0.7));
                        }
                    }

                    &.recent {
                        background: var(--ion-color-secondary);
                        text-shadow: -1px 1px 2px black;

                        .icon-path {
                            fill: white;
                            -webkit-filter: drop-shadow(13px 13px 10px rgba(0, 0, 0, 0.7));
                            filter: drop-shadow(13px 13px 10px rgba(0, 0, 0, 0.7));
                        }
                    }
                }
            }

            .divide {
                position: absolute;
                bottom: 6px;
                right: 0;
                width: 2px;
                height: 27px;
                background-color: black;
                opacity: 0.2;
                transform: rotate(10deg);
                z-index: 9;
            }

            .progress {
                height: 40px;
                position: absolute;
                width: 16px;
                bottom: -1px;
                overflow: hidden;
                right: -7px;
                background: transparent !important;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 4px;
                    width: 10px;
                    height: 21px;
                    z-index: 9;
                    border-right: 2px solid rgba(0, 0, 0, 0.3);
                    top: -1px;
                    transform: skew(20deg, 0);
                }

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 4px;
                    width: 10px;
                    height: 21px;
                    z-index: 9;
                    border-right: 2px solid rgba(0, 0, 0, 0.3);
                    bottom: -1px;
                    transform: skew(-20deg, 0);
                }

                &.complete,
                &.complete:before,
                &.complete:after {
                    background: $done;
                }

                &.recent,
                &.recent:before,
                &.recent:after {
                    background: var(--ion-color-secondary);
                }
            }

            .progress-rounded {
                position: absolute;
                bottom: -5px;
                right: -5px;
                width: 25px;
                height: 45px;
                z-index: 9;
                border-radius: 0 50% 50% 0;
                border-right: 2px solid $border-color;

                &.complete {
                    background: var(--ion-color-secondary);
                }

                &.recent {
                    background: $done;
                }
            }

            .completed {
                position: absolute;
                bottom: -27px;
                right: 14%;
                left: 14%;
                text-align: center;
                background: #e2e2e2;
                border-radius: 0 0 10px 10px;
                color: #333 !important;
                padding: 0px 4px 0 6px;
                font-size: 0.9em;
                height: 22px;
                width: 90px;

                &.editable {
                    .e-input-group.e-control-wrapper.e-date-wrapper.e-valid-input,
                    .e-input-focus {
                        width: 100% !important;
                        height: 20px !important;
                        border: 0;
                        color: #333;
                        align-items: center;
                        justify-content: center;

                        &:after,
                        &:before {
                            background: transparent;
                        }

                        .e-datepicker {
                            padding: 0;
                            margin: 0;
                            height: 20px !important;
                            width: 80px;
                        }

                        .e-input-group-icon.e-date-icon.e-icons {
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
