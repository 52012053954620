@mixin shortdesktop {
    @media (min-height: 320px) and (max-height: 955px) and (min-width: 700px) {
        @content;
    }
}

@media (min-width: 1025px) {
    ion-modal {
        --border-radius: 10px;
        --max-height: 100vh;
        --height: auto;
    }
    ion-modal.large {
        --width: 50vw;
        --height: auto;
    }
}

.swiper {
    height: 98vh;
    .swiper-slide {
        align-items: stretch;
        justify-content: stretch;
        flex-direction: column;
        height: 100%;
    }
}

ion-modal.desktop {
    --width: 35vw;
    &.small {
        --width: 20vw;
    }
    &.large {
        --width: 60vw;
    }
    &.xlarge {
        --width: 95vw;
        --background: transparent;
    }
}

.mobile .modal-content {
    height: 100vh;
}

.modal-content {
    height: 98vh;
    overflow: auto;
    .tools {
        position: absolute;
        top: 0;
        right: 0;
        padding: 14px;
        z-index: 29292;
        .icon-large {
            background-color: rgba(var(--ion-color-light-rgb), 0.4);
            border-radius: 50%;
            float: right;
            cursor: pointer;
        }
    }
    .title {
        background-color: rgba(var(--ion-color-light-rgb), 0.4);
        position: relative;
        top: -14px;
        left: -14px;
        padding: 10px 18px 10px 23px;
        border-radius: 0 0 20px 0;
    }
    .content {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.tabs {
    display: flex;
    gap: 15px;
    .tab {
        cursor: pointer;
        text-align: center;
        padding: 10px 25px;
        font-size: 1em;
        text-transform: uppercase;
        background-color: var(--ion-color-medium);
        font-family: var(--theme-bold-font);
        color: var(--ion-color-light);
        font-weight: bold;
        border-radius: 5px 5px 0 0;
        &.active {
            color: var(--ion-color-dark);
            background-color: var(--ion-color-light);
        }
        &:hover {
            background-color: var(--ion-color-light);
            color: var(--ion-color-primary);
        }
    }
}
.tab-body {
    background-color: var(--ion-color-light);
    padding: 15px;
    border-radius: 0 5px 5px 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.mobile {
    .tabs .tab {
        border-radius: 0 5px 0 0;
    }
    .tab-body {
        border-radius: 0;
    }
}
.mobile-tab {
    text-align: center;
    margin: 20px 0;
    cursor: pointer;
    color: var(--ion-color-primary);
    text-decoration: underline;
    text-transform: uppercase;
}

$popupColor: rgba(var(--backdrop-color-rgb), 0.7);
.popup {
    height: auto;
    .custom-popup {
        position: fixed;
        text-align: center;
        background-color: $popupColor;
        color: white;
        padding: 10px 20px;
        z-index: 13;
        border-radius: 5px;
        max-height: 100px;
        animation: fadeIn 1s;
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border: 10px solid transparent;
        }
        &.lft:after {
            top: calc(50% - 10px);
            left: 100%;
            border-left: 10px solid $popupColor;
        }
        &.rgt:after {
            top: calc(50% - 10px);
            right: 100%;
            border-right: 10px solid $popupColor;
        }
        &.btm:after {
            top: -20px;
            left: calc(50% - 10px);
            border-bottom: 10px solid $popupColor;
        }
        &.top:after {
            top: 100%;
            left: calc(50% - 10px);
            border-top: 10px solid $popupColor;
        }
    }
}

.close-icon {
    position: absolute;
    top: 36px;
    right: 15px;
    z-index: 999;
    ion-icon {
        font-size: 44px !important;
        cursor: pointer;
    }
    &:hover {
        ion-icon {
            color: var(--ion-color-primary);
        }
    }
}

.carousel {
    position: relative;
    min-height: 470px;
    @include shortdesktop() {
        min-height: 270px;
        height: 40vh;
    }
    &.max {
        position: relative;
        top: 32px;
        z-index: 12;
        height: 80vh;
        width: calc(100% - 70px);
        max-width: var(--theme-content-width);
        padding: 0 23px;
        margin: 0 auto;
        animation: fadeIn 1s;
        .item {
            cursor: default;
            width: 100%;
            height: 75vh;
            background-color: var(--ion-background-color);
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            img {
                margin: 0 auto;
                object-fit: contain;
                height: 100%;
                &::part(image) {
                    min-height: 470px;
                    object-fit: contain;
                    height: 100%;
                    @include shortdesktop() {
                        min-height: 370px;
                    }
                }
            }
        }
        .slide {
            border-radius: 0;
        }
        .item-slides {
            width: 100%;
            max-height: 95px;
            .wrap {
                padding: 5px;
                display: flex;
                justify-content: center;
                position: relative;
                img {
                    display: inline-block;
                    height: 79px;
                    margin-right: 10px;
                    cursor: pointer;
                }
                .slide {
                    border-radius: 15px;
                    max-width: 100px;
                    object-fit: cover;
                    &.active {
                        border: 4px solid var(--ion-color-primary);
                    }
                }
                .bullet {
                    border-radius: 10px;
                    font-size: 3em;
                    padding: 10px 10px 0;
                    background-color: var(--ion-color-dark);
                    display: inline-block;
                    border: 5px solid var(--ion-color-dark);
                    color: var(--ion-color-light);
                    cursor: pointer;
                    &:last-child {
                        margin-right: 10px;
                    }
                    &.active,
                    &:hover {
                        border: 5px solid var(--ion-color-primary);
                    }
                }
                .slide-btn {
                    height: 80px;
                    width: 100px;
                    margin-right: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.8em;
                    text-align: center;
                    gap: 5px;
                    &.active {
                        border: 2px solid var(--ion-color-primary);
                    }
                    ion-img {
                        height: 25px;
                        margin: 0 auto;
                    }
                    .doc-name {
                        line-height: 14px;
                    }
                }
            }
        }
        .prev {
            left: -33px;
        }
        .next,
        .prev {
            font-size: 4em;
            top: 51%;
            background-color: transparent;
        }
    }
    .item {
        cursor: pointer;
        min-height: 470px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 20px;
        @include shortdesktop() {
            min-height: 270px;
            height: 40vh;
        }
        ion-img {
            @include shortdesktop() {
                min-height: 270px;
            }
            &::part(image) {
                min-height: 470px;
                object-fit: cover;
                @include shortdesktop() {
                    min-height: 270px;
                    height: 40vh;
                }
            }
        }
    }
    .slide {
        display: none;
        border-radius: 15px;
        overflow: hidden;
        transition: width 1s ease-out;
        &.active {
            display: inline-block;
            &.white-bg {
                background-color: white;
                cursor: default;
            }
        }
    }
    .prev,
    .next {
        position: absolute;
        top: 45%;
        background-color: var(--ion-background-color);
        z-index: 11;
        font-size: 2em;
        height: 57px;
        width: 17px;
        cursor: pointer;
        &:hover {
            color: var(--ion-color-secondary);
        }
    }
    .prev {
        left: 0;
        border-radius: 0 12px 12px 0;
        padding: 10px 0 0;
        ion-icon {
            position: relative;
            left: -19px;
        }
    }
    .next {
        right: 0;
        border-radius: 12px 0 0 12px;
        padding: 10px 0 0;
        ion-icon {
            position: relative;
            right: -4px;
        }
    }

    .bullets {
        position: absolute;
        bottom: -7px;
        z-index: 11;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        display: flex;

        .wrap {
            height: 25px;
            background-color: var(--ion-background-color);
            margin: 0 auto;
            border-radius: 12px 12px 0 0;
            padding: 3px 0px 0 10px;
            .bullet {
                padding: 5px;
                margin-right: 10px;
                height: 10px;
                width: 10px;
                background-color: var(--ion-color-dark);
                display: inline-block;
                border-radius: 70px;
                border: 1px solid var(--ion-color-dark);
                cursor: pointer;
                &:last-child {
                    margin-right: 10px;
                }
                &.active,
                &:hover {
                    border: 1px solid var(--ion-color-secondary);
                    background-color: var(--ion-color-secondary);
                }
            }
        }
    }
}

.max .blt-nav {
    font-size: 2.5em;
    top: 20px;
}

.blt-nav {
    position: relative;
    top: 2px;
    left: -5px;
    cursor: pointer;
    &:hover {
        color: var(--ion-color-secondary);
    }
}
