#Orders {
    margin-bottom: 10px;
    position: relative;
    &.mobile {
        .order {
            .info {
                .salesperson {
                    text-align: left;
                }
            }
            .eta {
                margin-left: 10px;
                font-size: 0.9em;
            }
            .notifications {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: relative;
                top: 7px;
            }
        }
    }
    .right {
        text-align: right;
    }
    .refresh {
        cursor: pointer;
        font-size: 1.8em;
        position: absolute;
        right: 5px;
        top: 5px;
        &:hover {
            color: var(--ion-color-primary);
        }
    }
    .order {
        display: flex;
        flex-direction: column;
        padding: 15px;
        border-radius: 8px;
        background-color: var(--ion-color-light-tint);
        border: 1px solid var(--ion-color-light-shade);
        .info {
            align-items: center;
            justify-content: space-between;
            padding-right: 5px;
            .vehicle {
                display: flex;
                align-items: center;
                gap: 15px;
                .photo {
                    width: 117px;
                    height: 64px;
                    border-radius: 8px;
                    overflow: hidden;
                    text-align: center;
                    background-color: white;
                    ion-img {
                        object-fit: cover;
                        height: 100%;
                    }
                    ion-icon {
                        font-size: 4em;
                        color: #999;
                    }
                }
                .actions {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    .popup {
                        height: 18px;
                    }
                    .vinfo {
                        max-width: 24px;
                        cursor: pointer;
                    }
                    .docs {
                        cursor: pointer;
                        font-size: 1.6em;
                        &:hover {
                            color: var(--ion-color-primary);
                        }
                    }
                }
                &.mobile {
                    .details {
                        width: 100%;
                    }
                    .actions {
                        float: right;
                        .docs {
                            cursor: pointer;
                            font-size: 1.9em;
                            &:hover {
                                color: var(--ion-color-primary);
                            }
                        }
                    }
                }
            }
            .salesperson {
                label {
                    font-size: 0.8em;
                    font-weight: 100;
                    text-transform: uppercase;
                }
            }
            .points {
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
                label {
                    font-size: 0.8em;
                    text-transform: uppercase;
                }
                .point {
                    background-color: var(--ion-color-step-300);
                    border-radius: 5px;
                    padding: 10px;
                    margin-top: 3px;
                    font-size: 0.9em;
                }
                p.text {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .lid {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
            }
        }
    }
    .deet {
        font-weight: 100;
        font-weight: bold;
        margin-left: 10px;
        color: var(--ion-color-dark-shade);
        &.primary {
            color: var(--ion-color-primary);
            label {
                color: var(--ion-text-color);
            }
        }
        &:first-child {
            margin-left: 0;
            label {
                border-left: 0;
                padding-left: 0;
            }
        }
        label {
            font-weight: 100;
            padding-left: 10px;
            border-left: 1px solid var(--ion-text-color);
            font-size: 0.8em;
        }
    }
}
